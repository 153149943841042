import React from 'react'
import styled from 'styled-components'
import BackAlliances from '../../images/svg/back-alliances.svg'
import Carousel from 'react-elastic-carousel'
import ImgAlliances from './ImgAlliances'

const Alliances = ({ title, aliancesData, allies }) => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 600, enableSwipe: false, enableMouseSwipe: false, pagination: false },
  ]
  return (
    <Styled>
      <BackAlliances className='backImage' />
      <div className='alliance'>
        <h2 className='title'>{title}</h2>
        <div className='content' dangerouslySetInnerHTML={{ __html: aliancesData }}></div>
      </div>
      <Container
        itemsToShow={1}
        breakPoints={breakPoints}
        showArrows={false}
        itemPadding={[10, 4, 10, 4]}
        style={{ alignItems: 'center' }}>
        {allies.map((item, i) =>
          <div key={i} className='slider'>
            <div className='card'>
              <ImgAlliances alt={item.title} filename={item.image} />
            </div>
            <p>{item.title}</p>
          </div>
        )}
      </Container>
    </Styled>
  )
}

export default Alliances

const Styled = styled.section`
  position: relative;
  padding: 2rem 0 4rem;
  overflow-x: hidden;
  .backImage{
    position: absolute;
    top: -100px;
    height: auto;
    z-index: -1;
    right: -40px;
    width: 140px;
    transition: all linear .5s;
    @media screen and (min-width: 600px){
      width: 200px;
      top: -100px;
      right: -20px;
    }
    @media screen and (min-width: 1100px){
      top: -140px;
      width: 300px;
      right: -50px;
    }
  }
  .alliance{
    margin: 0 auto 30px;
    max-width: 400px;
    padding: 0 2rem 0 1rem;
  }
  .title{
    margin-bottom: 1rem;
  }
  .content{
    color: ${props => props.theme.colors.text.secondary};
    p{
      font-size: 0.875rem;
      line-height: 2em;
      margin-bottom: 1rem;
    }
    p:nth-last-child(1){
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: 600px){
    padding: 3.75rem;
    .title{
      text-align: center;
      margin-bottom: 2rem;
    }
    .content{
      text-align: center;
      p{
        font-size: 1.125rem;
      }
    }
  }
`
const Container = styled(Carousel)`
  overflow-x:hidden;
  .rec-slider-container{
    overflow: initial;
    padding: 0 26px;
    margin-top: 10px;
  }
  .rec-slider{
    min-height: initial;
    padding: 0 26px;
  }
  .rec-item-wrapper{
    overflow: initial;
    height: 100%;
    .slider{
      width:70%;
      .card{
        width: 90%;
      }
      p{
        margin-bottom: 0;
        text-align: center;
        font-size: .875rem;
        line-height: 2rem;
        color: ${props => props.theme.colors.text.primary};
      }
    }
  }
  @media screen and (min-width: 600px){
    .rec-slider-container{
      overflow: hidden;
      padding: initial;
      margin: 0 10rem;
    }
    .rec-carousel{
    }
    .rec-slider{
      padding: initial;
      flex-wrap: wrap;
      min-height: initial;
      left: initial!important;
      width: 100%;
      > div{
        width: 25%;
      }
    }
    .rec-item-wrapper{
      overflow: hidden;
      width:100%!important;
      height:100%;
      padding: 10px!important;
      .slider{
        width: 100%;
        p{
          font-size: 1.125rem;
        }
      }
    }
    .rec-pagination{
      display: none;
    }
  }
  .rec-dot{
    border: 0;
    background-color: #00ABC2;
    width: 8px;
    height: 8px;
    margin: 4px;
    box-shadow: none;
    &.rec-dot_active{
      background-color: rgba(0,171,194,0.5);
    }
  }
`