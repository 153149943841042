import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Aliances from '../components/alianzas/Alliances'
//data
import pageData from "../data/pageData"

const Alianzas = ({ location }) => (
  <Layout location={location}>
    <SEO title="Alianzas" />
    <Aliances
      title={pageData.aliances.title}
      aliancesData={pageData.aliances.about}
      allies={pageData.aliances.allies.items} />
  </Layout>
)

export default Alianzas